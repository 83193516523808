.news {
  border: 1px solid #edeff2;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
}

.news__link {
  display: flex;
  margin-bottom: 16px;
}

.news__img {
  width: 100%;
}

.news__date {
  display: flex;
  align-items: flex-end;
  color: #b2bac7;
  padding-left: 16px;
  margin-bottom: 16px;
  font-size: 14px;
}

.news__date-img {
  width: 20px;
  height: 20px;
  stroke: currentColor;
  margin-right: 8px;
}

.news__name {
  margin-bottom: 16px;
  padding: 0 16px;
  text-decoration: none;
  color: #303031;
  font-weight: bold;
  height: 32px;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
