.product-swiper__title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .product-swiper__title {
    margin-bottom: 24px;
  }
}

@media (min-width: 1080px) {
  .product-swiper__title {
    align-items: flex-end;
  }
}

.product-swiper__title-text {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-right: auto;
}

@media (min-width: 1080px) {
  .product-swiper__title-text {
    font-size: 34px;
    line-height: 40px;
  }
}

.product-swiper__title-text--small {
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 1080px) {
  .product-swiper__title-text--small {
    font-size: 24px;
    line-height: 34px;
  }
}

.product-swiper__btn {
  text-decoration: none;
  line-height: 32px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid currentColor;
  border-radius: 10px;
  background-color: transparent;
  color: #bc191d;
  height: 32px;
  font-size: 10px;
  padding: 0 12px;
  font-family: inherit;
  transition-property: color, background-color;
  transition-duration: .3s;
}

@media (min-width: 768px) {
  .product-swiper__btn {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    padding: 0 24px;
  }
}

.product-swiper__btn:hover {
  background-color: #bc191d;
  color: #fff;
}

@media (min-width: 1080px) {
  .product-swiper__btn--all {
    margin-right: 30px;
  }
}

.product-swiper__button-nav {
  display: none;
}

@media (min-width: 1080px) {
  .product-swiper__button-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 38px;
    color: #fff;
    background-color: #bc191d;
    border-radius: 10px;
    border: 1px solid #bc191d;
  }
}

.product-swiper__button-nav:enabled {
  cursor: pointer;
}

.product-swiper__button-nav:disabled {
  border-color: #edeff2;
  background-color: #fff;
  color: #b2bac7;
}

.product-swiper__button-nav--prev {
  margin-right: 10px;
}

.product-swiper__button-nav-img {
  fill: currentColor;
  width: 9px;
  height: 16px;
}

.product-swiper__button-nav-img--prev {
  transform: rotate(180deg);
}

.product-swiper__scrollbar {
  position: relative;
  background: rgba(0, 0, 0, .1);
  width: 100%;
  height: 1px;
  margin: 20px 0 5px;
}

@media (min-width: 1080px) {
  .product-swiper__scrollbar {
    display: none;
  }
}

.product-swiper__scrollbar-drag {
  position: absolute;
  border-radius: 4px;
  height: 8px;
  background: #edeff2;
  top: -4px;
  cursor: pointer;
}
