.section {
  margin-bottom: 37px;
}

@media (min-width: 768px) {
  .section {
    margin-bottom: 27px;
  }
}

.section--special {
  margin-bottom: 8px;
}

@media (min-width: 1080px) {
  .section--special {
    display: flex;
    margin-bottom: 24px;
  }

  .section-column-special {
    z-index: 1;
    flex-shrink: 0;
    margin-right: 16px;
  }
}

.wrapper-home-nav-catalog {
  display: none;
}

@media (min-width: 1080px) {
  .wrapper-home-nav-catalog {
    display: block;
    z-index: 1;
    flex-shrink: 0;
    margin-right: 16px;
  }
}

.section--news {
  margin-bottom: 0;
}

@media (min-width: 1080px) {
  .swipper-special {
    width: calc(100% - 307px - 16px);
  }
}

.swipper-special__swiper-pagination {
  position: absolute;
  left: 8px !important;
  bottom: 16px !important;
}

@media (min-width: 768px) {
  .swipper-special__swiper-pagination {
    left: 24px !important;
    bottom: 24px !important;
  }
}

.swipper-special__swiper-pagination-bullet {
  border: 2px solid #fff;
  background-color: transparent;
  margin: 4px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  transition: background-color .3s ease;
  outline: none;
  padding: 0;
}

.swipper-special__swiper-pagination-bullet--active {
  background-color: #fff;
}

.swipper-special__link {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.swipper-special__img {
  width: 100%;
}

/* ********************* */

.btn-benefits-catalog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  background-color: #bc191d;
  border: 1px solid #bc191d;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 16px;
  border-radius: 10px;
  width: 100%;
  font-family: inherit;
}

@media (min-width: 1080px) {
  .btn-benefits-catalog {
    display: none;
  }
}

.btn-benefits-catalog__img {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  stroke: #fff;
}

.benefits {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
}

@media (min-width: 1080px) {
  .benefits {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
  }
}

.benefits__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #fff;
  border: 1px solid #edeff2;
  border-radius: 10px;
  padding: 8px;
  margin: 2px;
}

@media (min-width: 600px) {
  .benefits__item {
    align-items: center;
  }
}

@media (min-width: 768px) {
  .benefits__item {
    flex-direction: row;
    padding: 24px;
  }
}

.benefits__item-img {
  width: 32px;
  height: 32px;
  margin-bottom: 6px;
}

@media (min-width: 768px) {
  .benefits__item-img {
    width: 50px;
    height: 50px;
    margin: 0 16px 0 0;
  }
}

.benefits__item-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #303031;
}

@media (min-width: 600px) {
  .benefits__item-text {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .benefits__item-text {
    text-align: left;
  }
}

.benefits__item-text-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}

@media (min-width: 768px) {
  .benefits__item-text-title {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}

@media (min-width: 1080px) {
  .benefits__item-text-title {
    font-size: 21px;
  }
}

@media (min-width: 1200px) {
  .benefits__item-text-title {
    font-size: 24px;
  }
}

.benefits__item-text-additional {
  line-height: 18px;
  font-size: 14px;
}

@media (min-width: 600px) {
  .benefits__item-text-additional {
    line-height: 12px;
  }
}
